import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/functions'
import 'firebase/storage'
import 'firebase/analytics'

const firebaseConfig = {
  apiKey: "AIzaSyBbh2_DA_tY5-uk3entDXUY_wdlbj61mNE",
  authDomain: "oses-beefreund.firebaseapp.com",
  databaseURL: "https://oses-beefreund-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "oses-beefreund",
  storageBucket: "oses-beefreund.appspot.com",
  messagingSenderId: "255764056104",
  appId: "1:255764056104:web:b408f461167e2242ad8d1d",
  measurementId: "G-LT0CD2T7J2"
};

const fb = firebase.initializeApp(firebaseConfig)
const veri = fb.database()
const fonksiyon = fb.functions()
const depo = fb.storage()

firebase.analytics();

export {
  fb,
  veri,
  fonksiyon,
  depo,
}
